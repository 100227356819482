
import store from '@/store';
import { defineComponent, computed } from 'vue';
import { modalsNames } from '@/utils/modals';
import CommonModal from './CommonModal.vue';
import ContactsForm from './ContactsForm.vue';

export default defineComponent({
  name: 'ModalContacts',
  components: {
    ContactsForm,
    CommonModal,
  },
  setup() {
    const hash = computed(() => store.getters.routeStore.hash);
    const data = computed(() => ({
      name: hash.value.name || '',
      id: hash.value.id || '',
    }));

    return {
      modalName: modalsNames.contacts,
      data,
    };
  },
});
