
import '@/assets/icons/mastercard-icon.svg?sprite';
import store from '@/store';
import { computed, defineComponent } from 'vue';
import { multiply } from 'rambda';
import curr from 'currency.js';
import modals, { modalsNames } from '@/utils/modals';
import routeHash from '@/utils/routeHash';
import changeCurr from '@/utils/changeCurr';
import { useI18n } from 'vue-i18n';

import CommonInput from './CommonInput.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonModal from './CommonModal.vue';
import CommonTabs from './CommonTabs.vue';

export const tabsList = [
  { value: 'btc', label: 'Bitcoin' },
  { value: 'paystree', label: 'OTON Paystree' },
];

export default defineComponent({
  name: 'ModalWithdrawBtc',
  components: {
    CommonModal,
    CommonTabs,
    CommonInput,
    CommonButtonPrimary,
  },
  setup() {
    const { t } = useI18n();
    const modalName = modalsNames.withdrawBtc;

    const hash = computed(() => store.getters.routeStore.hash);
    const data = computed(() => ({

      iban: hash.value.iban || '',

      btcaddress: hash.value.btcaddress || '',
      beneficiaryName: hash.value.beneficiaryName || '',

      sum: hash.value.sum ? String(hash.value.sum) : '',

      tab: (tabsList.some(({ value }) => value === hash.value.tab) ? hash.value.tab : 'btc') as 'btc' | 'paystree',
    }));

    const commission = computed(() => ({
      btc: {
        label: `1,5%. ${t('Minimum withdrawal amount is {sum} {curr}', { sum: 0.01, curr: 'BTC' })}`,
        calc: multiply(0.015),
      },
      paystree: {
        label: t('No fee'),
        calc: multiply(0),
      },
    }));

    const forms = computed<{
      [key: string]: Array<{
        type: string;
        label: string;
        name: string;
        placeholder?: string;
        addClass?: string;
        hasError: boolean;
      }>
    }>(() => {
      const { iban } = data.value;
      return ({
        btc: [
          {
            type: 'string',
            label: 'BTC address',
            name: 'btcaddress',
            addClass: 'fluid',
            get hasError() {
              return false;
            },
          },
        ],
        paystree: [
          {
            type: 'string',
            label: 'Beneficiary name',
            name: 'beneficiaryName',
            get hasError() {
              return false;
            },
          },
          {
            type: 'string',
            label: 'IBAN',
            name: 'iban',
            mask: 'GB ## XXXXXX XXXXX XXXXXXX',
            placeholder: 'GB',
            get hasError() {
              if (!iban) {
                return false;
              }

              return String(iban).replace(/\s/g, '').length !== 22;
            },
          },
        ],
      });
    });

    const formIsValid = computed(() => forms.value[data.value.tab]
      .every((form: any) => !form.hasError));

    const sumInEur = computed(() => changeCurr('btc', 'eur', data.value.sum)
      .multiply(data.value.tab === 'paystree' ? 0.985 : 1)
      .format());
    const oneBtcInEur = computed(() => changeCurr('btc', 'eur', 1)
      .multiply(data.value.tab === 'paystree' ? 0.985 : 1)
      .format());

    const btcRateSettings = store.getters.exchangeRatesStore.ratesSettings.btc;
    const fee = computed(() => curr(
      commission.value[data.value.tab].calc((+data.value.sum || 0)),
      btcRateSettings,
    ));

    const sumWithFee = computed(() => fee.value.add(data.value.sum));

    const isSumVaid = computed(() => {
      const sum = +data.value.sum || 0;
      let isValid = false;

      isValid = sum > fee.value.value;

      if (data.value.tab === 'btc') {
        isValid = isValid && sum >= 0.01;
      }

      return isValid;
    });

    const onChange = (name: string, value: string) => {
      routeHash.replace({ [name]: value });
    };
    const changeTab = (name: string) => {
      onChange('tab', name);
    };
    const onSubmit = () => modals.withdrawBtcAccept.openModal({
      ...data.value,
      fee: fee.value.format(),
      sumWithFee: sumWithFee.value.value,
    });

    const ratesState = computed(() => store.state.exchangeRatesStore.fetchState);

    return {
      modalName,
      tabsList,
      forms,
      data,
      commission,
      fee,
      sumWithFee,
      onChange,
      onSubmit,
      changeTab,
      isSumVaid,
      formIsValid,
      changeCurr,
      sumInEur,
      oneBtcInEur,
      ratesState,
    };
  },
});
