import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { pathOr, pipe } from 'rambda';

import { notifyHandler } from '@/utils/notifyHandler';
import { getLocale } from '@/langs/i18n';
import authCookies from './cookies/authCookies';

const buildFormData = (formData: any, data: any, parentKey?: any) => {
  const isDataObject = data && typeof data === 'object';
  if (isDataObject && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
};

export const jsonToFormData = (data: any) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};

export class ApiInstance {
  public baseURL: string;

  public changeRequestData: (data: any) => any;

  public paramsValidator: (props: AxiosRequestConfig) => boolean;

  public transformResponse: Array<(data: any) => any>;

  public catchErr: (e: any) => void;

  public sendByGet(url: string, props?: any) {
    return axios({
      method: 'GET',
      baseURL: this.baseURL,
      url,
      withCredentials: false,
      ...(props || {}),
    });
  }

  constructor({
    baseURL,
    changeRequestData = (data) => data,
    transformResponse,
    catchErr,
    paramsValidator = () => true,
  }: {
    baseURL: string;
    changeRequestData?: (data: any) => any;
    transformResponse: Array<(data: any) => any>;
    catchErr: (e: any) => void;
    paramsValidator?: (props: AxiosRequestConfig) => boolean;
  }) {
    this.baseURL = baseURL;
    this.changeRequestData = changeRequestData;
    this.transformResponse = transformResponse;
    this.catchErr = catchErr;
    this.paramsValidator = paramsValidator;
  }

  public get standardConfig() {
    return {
      withCredentials: false,
      baseURL: this.baseURL,
      transformResponse: [(resp: any) => JSON.parse(resp), ...this.transformResponse],
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': getLocale(),
      },
    };
  }

  public sendPost = async (props: AxiosRequestConfig) => {
    if (!this.paramsValidator(props)) {
      const message = `Validate props error. Method: ${this.baseURL}${props.url}`;

      console.error(message, '\n', props);
      throw new Error(message);
    }

    const instance = axios.create({
      ...this.standardConfig,
      method: 'POST',
      transformRequest: [this.addParamsToData, (req) => JSON.stringify(req)],
    });

    try {
      return await instance({
        method: 'POST',
        ...props,
      });
    } catch (e: any) {
      this.catchErr(e);
      throw new Error(e);
    }
  };

  public clearPost = async (props: AxiosRequestConfig) => {
    try {
      return await axios({
        method: 'POST',
        ...props,
      });
    } catch (e: any) {
      this.catchErr(e);
      throw new Error(e);
    }
  };

  public sendFormDataPost(url: string) {
    return (data: any) => {
      const formData = pipe(this.addParamsToData, jsonToFormData)(data);

      return axios({
        method: 'POST',
        withCredentials: false,
        data: formData,
        url,
        baseURL: this.baseURL,
        headers: { 'Content-Type': 'multipart/form-data' },
        transformResponse: [
          (resp: any) => {
            const response = JSON.parse(resp);
            notifyHandler(pathOr([], ['message'], response));
            return data;
          },
        ],
      });
    };
  }

  private readonly addParamsToData = (data: any) => this.changeRequestData(data);
}

export const api = {
  office: new ApiInstance({
    baseURL: process.env.VUE_APP_OFFICE_BASE_API_URL,
    changeRequestData: (data: any) => {
      const newData = {
        lang: getLocale(),
        signature: 1,
        domain: process.env.VUE_APP_OFFICE_DOMAIN,
        ...data,
      };

      const auth = authCookies.get();

      if (auth) {
        newData.auth = auth;
      }
      return newData;
    },

    transformResponse: [
      (data: any) => {
        if (data.code === 401 && authCookies.get()) {
          Cookies.remove('auth');
          window.location.replace('/login');
          return data;
        }
        notifyHandler(pathOr([], 'message', data));
        return data;
      },
    ],
    catchErr: () => false,
  }),
  adapter: new ApiInstance({
    baseURL: process.env.VUE_APP_ADAPTER_BASE_API_URL,
    changeRequestData: (data: any) => data,
    transformResponse: [],
    catchErr: (e) => {
      let messages: any = pathOr([], 'response.data.message', e);
      messages = Array.isArray(messages) ? messages : [messages];
      messages = messages
        .filter((message: string) => !!message)
        .map((message: string) => ({
          text: message,
          theme: 'error',
        }));

      notifyHandler(messages);
    },
    paramsValidator: (props) => {
      const { data } = props;

      if (typeof data.drivers === 'object') {
        return Object
          .values(data.drivers)
          .every((el: any) => typeof el === 'object' && !!el && !!el.token);
      }

      return !!data.token;
    },
  }),
};
