import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-657b3391"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonInput" }
const _hoisted_2 = {
  key: 0,
  class: "header"
}
const _hoisted_3 = { class: "label" }
const _hoisted_4 = {
  key: 0,
  class: "maxLength"
}
const _hoisted_5 = { class: "inputBox" }
const _hoisted_6 = ["value", "type", "maxlength", "placeholder", "required", "pattern", "autocomplete"]
const _hoisted_7 = {
  key: 1,
  class: "errorLabel"
}
const _hoisted_8 = {
  key: 2,
  class: "errorBox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    (!!_ctx.$slots.default || _ctx.label || _ctx.maxLength)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ], true)
          ]),
          (!!_ctx.maxLength)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("b", {
                  class: _normalizeClass({ maxLength_hasError: _ctx.maxLength < String(_ctx.modelValue).length })
                }, _toDisplayString(String(_ctx.modelValue).length), 3),
                _createTextVNode("/" + _toDisplayString(_ctx.maxLength), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (!!_ctx.$slots.prefix)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['inputPrefix', _ctx.inputPrefixClass])
          }, [
            _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true),
      (!!_ctx.$slots.suffix)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(['inputSuffix', _ctx.inputSuffixClass])
          }, [
            _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", _mergeProps({
        style: _ctx.inputStyle,
        class: _ctx.inputClass,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateInput && _ctx.updateInput(...args))),
        onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keydown && _ctx.keydown(...args))),
        type: _ctx.type,
        maxlength: _ctx.maxLength,
        placeholder: _ctx.placeholder,
        required: _ctx.required,
        pattern: _ctx.pattern,
        autocomplete: _ctx.autocomplete
      }, _ctx.$attrs, {
        ref: (el) => _ctx.$emit('innerRef', el)
      }), null, 16, _hoisted_6), [
        [_directive_maska, _ctx.mask]
      ])
    ]),
    (!!_ctx.error && !!_ctx.errorLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.errorLabel), 1))
      : _createCommentVNode("", true),
    (!!_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}