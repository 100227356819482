/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';

import { pathOr, clone } from 'rambda';
import { ListNewsInterface, NewsItemInterface } from '@/interfaces/NewsTypes';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  basicOfficeFetch,
  State,
} from '@/store/storeUtils';
import { INIT, PENDING, FULFILLED } from '@/utils/statuses';

export type NewsResponse = AxiosResponse<{ data: ListNewsInterface }>;

export interface NewsStateInterface {
  data: NewsItemInterface[];
  fetchState: State;
  canFetchMore: boolean;
  page: number;
  limit: number;
}

const initialState: NewsStateInterface = {
  data: [],
  fetchState: INIT,
  canFetchMore: false,
  page: 1,
  limit: 20,
};

const newsStore = defineModule({
  namespaced: true,
  state: clone(initialState),

  getters: {
    fetchMoreState(...args): State {
      const { state } = getterCtx(args);

      if (state.data.length > 0) {
        return state.fetchState;
      }
      return FULFILLED;
    },
    fetchState(...args): State {
      const { state } = getterCtx(args);

      if (state.data.length <= 0) {
        return state.fetchState;
      }
      return FULFILLED;
    },
  },
  mutations: {
    CLEAR_STORE(state) {
      Object.assign(state, clone(initialState));
    },
    PREPARE_FOR_FIRST_FETCH(state) {
      Object.assign(state, initialState);
    },
    SET_STATE(state, { fetchState, data }: { fetchState: State; data: NewsResponse }) {
      state.fetchState = fetchState;

      if (data) {
        const list = pathOr([], 'data.data.news', data);
        const lengthList = pathOr(0, 'data.data.length', data);

        state.canFetchMore = list.length === 20 && lengthList > state.data.length;

        state.data = [...state.data, ...list];
      }
    },
    SET_INIT(state) {
      state.fetchState = INIT;
    },
    SET_PAGE(state, page: number) {
      state.page = page;
    },
  },
  actions: {
    async createReq(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: `/news/newslist/${state.page}`,
          data: {
            limit: state.limit,
          },
        },
        setState: commit.SET_STATE,
        callback: (fetchState) => {
          if (fetchState === FULFILLED) {
            commit.SET_PAGE(state.page + 1);
          }
        },
      });
    },
    async fetch(ctx, props: { fromZero?: boolean }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      if (props.fromZero) {
        commit.PREPARE_FOR_FIRST_FETCH();
      }

      await dispatch.createReq();
    },
  },
});

export default newsStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, newsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, newsStore);
