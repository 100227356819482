/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { path } from 'rambda';

import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicOfficeFetch,
} from '@/store/storeUtils';
import {
  PENDING, REJECTED,
} from '@/utils/statuses';

export interface PointsListItemsInterface {
  date: number | string;
  marketplace_id: number | string;
  points: number | string;
  status: number | string;
  unfreeze: number | string;
}

export interface PointsListInterface {
  point: PointsListItemsInterface[];
}

type StateInterface = FetchOfficeState<PointsListInterface>;

const pointsListStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<StateInterface>(),
  } as StateInterface,

  getters: {
    data(...args): PointsListInterface {
      const { state } = getterCtx(args);

      const result = path('data.data', state.response) as PointsListInterface;

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
  },
  actions: {

    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/loan/loanPointsList',
          data: {},
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            console.error('Fetch error in loanPointsStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default pointsListStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, pointsListStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, pointsListStore);
