import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3da6ed69"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "addContact__container" }
const _hoisted_2 = { class: "addContact__address" }
const _hoisted_3 = { class: "addContact__addressLabel" }
const _hoisted_4 = {
  key: 0,
  class: "addContact__buttons"
}
const _hoisted_5 = {
  key: 1,
  class: "addContact__buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonLoader = _resolveComponent("CommonLoader")!
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonButtonSecondary = _resolveComponent("CommonButtonSecondary")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CommonLoader, {
      state: _ctx.editState,
      isPlaceholder: ""
    }, null, 8, ["state"]),
    _createElementVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CommonInput, {
          modelValue: _ctx.data.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange('name', $event)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Name")), 1)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_CommonInput, {
          modelValue: _ctx.data.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange('email', $event))),
          type: "mail"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Email")), 1)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_CommonInput, {
          modelValue: _ctx.data.telegram,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChange('telegram', $event)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Telegram")), 1)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, "BTC " + _toDisplayString(_ctx.$t("Address")), 1),
        _createVNode(_component_CommonInput, {
          required: "",
          modelValue: _ctx.data.btcAddress,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChange('btcAddress', $event)))
        }, null, 8, ["modelValue"])
      ]),
      (_ctx.data.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_CommonButtonPrimary, {
              onClick: _ctx.onSave,
              type: "submit",
              class: "addContact__buttonsItem"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Save")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_CommonButtonSecondary, {
              onClick: _ctx.onRemove,
              class: "addContact__buttonsItem"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Delete")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_CommonButtonPrimary, {
              onClick: _ctx.onSave,
              type: "submit",
              class: "addContact__buttonsItem"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Add")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
    ], 32)
  ], 64))
}