import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ab3b156"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modalOpenSupport" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonLoader = _resolveComponent("CommonLoader")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: _ctx.$t('Attention!'),
    isModalOpenSupport: true,
    onOpened: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fetchSupportLink()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonLoader, {
        isPlaceholder: "",
        state: _ctx.formData.state
      }, null, 8, ["state"]),
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t('You go to the support section.')) + " ", 1),
        _createElementVNode("a", {
          href: _ctx.formData.data,
          target: "_blank",
          class: "modalOpenSupport__link"
        }, [
          _createVNode(_component_CommonButtonPrimary, {
            class: "modalOpenSupport__button",
            disabled: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Go')), 1)
            ]),
            _: 1
          })
        ], 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["name", "title"]))
}