import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09f9ba6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "checked", "value", "disabled", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(['checkbox-container', { disabled: _ctx.disabled }])
  }, [
    _createElementVNode("input", {
      class: "checkbox-inputCheckbox",
      type: _ctx.type,
      checked: _ctx.shouldBeChecked,
      value: _ctx.value,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateInput && _ctx.updateInput(...args))),
      disabled: _ctx.disabled,
      name: _ctx.name
    }, null, 40, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass(["checkbox-checkbox", { 'checkbox-checkbox_radio': _ctx.type === 'radio' }])
    }, null, 2),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}