
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CommonInputCheckbox',
  components: { },
  emits: ['change'],
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    type: {
      type: String,
      required: false,
      validator: (value: string) => ['checkbox', 'radio'].indexOf(value) !== -1,
      default: 'checkbox',
    },
    name: {
      type: String,
    },
    value: {
      type: String,
    },
    modelValue: {
      default: false,
    },
    // Мы установили `true-value` и `false-value` в true и false по-умолчанию, таким образом
    // мы всегда можем использовать их вместо проверки на то, установлены они или нет.
    // Также здесь мы можем использовать camelCase, дефис разделяющий имя атрибута
    // все равно будет работать
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    shouldBeChecked(): boolean {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value);
      }
      // Обратите внимание, что `true-value` и` false-value` являются camelCase в JS
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event: any) {
      const isChecked = event.target.checked;

      if (Array.isArray(this.modelValue)) {
        const newValue = [...this.modelValue];

        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }

        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
});
