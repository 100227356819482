/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { defineModule } from 'direct-vuex';
import { pathOr } from 'rambda';

import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicAdapterFetch,
} from '@/store/storeUtils';
import { INIT, REJECTED } from '@/utils/statuses';
import { getLocale } from '@/langs/i18n';
import authCookies from '@/utils/cookies/authCookies';

export interface TransactionTypeInterface {
  driver: 'oton';
  id: number;
  name: string;
}

export interface TransactionsTypesStoreInterface
  extends FetchOfficeState<TransactionTypeInterface[]> {
  fetchedKey: string;
}

const transactionsTypesStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<TransactionTypeInterface[]>(),
    fetchedKey: '',
  } as TransactionsTypesStoreInterface,

  getters: {
    data(...args): TransactionTypeInterface[] {
      const { state } = getterCtx(args);

      const result = pathOr([], ['data', 'data'], state.response) as TransactionTypeInterface[];

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = INIT;
    },
    CHANGE_FETCH_KEY(state, payload: { fetchedKey: string }) {
      state.fetchedKey = payload.fetchedKey;
    },
  },
  actions: {
    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      const auth = authCookies.get();

      const currentFetchKey = `${auth}${getLocale()}`;

      if (currentFetchKey === state.fetchedKey) {
        return;
      }

      commit.CHANGE_FETCH_KEY({ fetchedKey: currentFetchKey });

      await basicAdapterFetch({
        method: api.adapter.sendPost,
        props: {
          url: '/oton/transactions/types',
          data: {
            token: auth,
          },
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            commit.CHANGE_FETCH_KEY({ fetchedKey: '' });
          }
        },
      });
    },
  },
});

export default transactionsTypesStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, transactionsTypesStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, transactionsTypesStore);
