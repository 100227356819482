
import store from '@/store';
import { computed, defineComponent } from 'vue';
import modals, { modalsNames } from '@/utils/modals';
import curr from 'currency.js';
import routeHash from '@/utils/routeHash';
import changeCurr, { getCurr } from '@/utils/changeCurr';
import { pathOr } from 'rambda';
import CommonInput from './CommonInput.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonModal from './CommonModal.vue';

import CommonLoader from './CommonLoader.vue';
import CommonTabs from './CommonTabs.vue';

export const tabsList = [
  { value: 'bep20', label: 'BEP20 address' },
];

export default defineComponent({
  name: 'ModalWithdrawOton',
  components: {
    CommonModal,
    CommonInput,
    CommonButtonPrimary,
    CommonLoader,
    CommonTabs,
  },
  setup() {
    const modalName = modalsNames.modalWithdrawOton;

    const frozenState = computed(() => store.state.userFrozenOtonStore.fetchState);
    const frozenData = computed(() => store.getters.userFrozenOtonStore.data);

    const otonBalance = computed(() => {
      if (!frozenData.value) {
        return {};
      }

      return {
        limit: getCurr('oton', frozenData.value.totalWithdrawLimit).format(),
      };
    });

    const otonqBalance = computed(() => pathOr('0', 'balance.otonq.balance', store.getters.userStore.data));

    const otonqInNum = computed(() => Number(otonqBalance.value.replace(/,/g, '')));

    const otonqInEur = computed(() => changeCurr('oton', 'eur', otonqInNum.value));

    const otonqInBtc = computed(() => changeCurr('oton', 'btc', otonqInNum.value));

    const hash = computed(() => store.getters.routeStore.hash);
    const data = computed(() => ({
      bep20: hash.value.bep20 || '',
      fee: hash.value.fee || 'eur',

      beneficiaryName: hash.value.beneficiaryName || '',
      iban: hash.value.iban || '',

      sum: hash.value.sum ? String(hash.value.sum) : '',
      tab: (tabsList.some(({ value }) => value === hash.value.tab) ? hash.value.tab : 'bep20') as 'bep20' | 'paystree',
    }));

    const feesValues = computed(() => {
      const otonSum = curr((+data.value.sum || 0));
      const otonRate = Math.trunc(curr((+data.value.sum || 0)).multiply(0.99).value);
      let otonFee = otonSum.subtract(otonRate).value;

      if (otonFee < 20) {
        otonFee = 20;
      }

      if (data.value.tab === 'paystree') {
        otonFee = 0;
      }

      return {
        oton: otonFee,
        eur: 1.5,
        btc: changeCurr('eur', 'btc', 1.5).format(),
      };
    });

    const sumWithFee = computed(() => {
      const withFee = curr(+data.value.sum || 0).subtract(feesValues.value.oton).value;
      return withFee > 0 ? withFee : 0;
    });

    const isValidSum = computed(() => {
      const sum = sumWithFee.value;
      const limit = pathOr(0, 'value.totalWithdrawLimit', frozenData);

      return (sum <= limit && sum !== 0);
    });

    const forms = computed(() => {
      const { iban } = data.value;
      return ({
        bep20: [
          {
            type: 'string',
            label: 'BEP20-address',
            name: 'bep20',
            addClass: 'fluid',
            get hasError() {
              return false;
            },
          },
        ],
        paystree: [
          {
            type: 'string',
            label: 'Beneficiary name',
            name: 'beneficiaryName',
            addClass: '',
            get hasError() {
              return false;
            },
          },
          {
            type: 'string',
            label: 'IBAN',
            name: 'iban',
            addClass: '',
            mask: 'GB ## XXXXXX XXXXX XXXXXXX',
            placeholder: 'GB',
            get hasError() {
              if (!iban) {
                return false;
              }

              return String(iban).replace(/\s/g, '').length !== 22;
            },
          },
        ],
      });
    });

    const formIsValid = computed(() => forms.value[data.value.tab]
      .every((form: any) => !form.hasError));

    const onChange = (name: string, value: string) => routeHash.replace({ [name]: value });
    const changeTab = (name: string) => {
      onChange('tab', name);
    };
    const onSubmit = () => modals.modalWithdrawOtonAccept.openModal(data.value);

    const sumInEur = computed(() => changeCurr('oton', 'eur', data.value.sum)
      .multiply(data.value.tab === 'paystree' ? 0.985 : 1)
      .format());
    const oneOtonInEur = computed(() => changeCurr('oton', 'eur', 1)
      .multiply(data.value.tab === 'paystree' ? 0.985 : 1)
      .format());

    const ratesState = computed(() => store.state.exchangeRatesStore.fetchState);

    return {
      modalName,
      frozenState,
      otonBalance,
      otonqBalance,
      otonqInNum,
      otonqInEur,
      otonqInBtc,
      data,
      sumWithFee,
      feesValues,
      onChange,
      onSubmit,
      changeTab,
      isValidSum,
      formIsValid,
      forms,
      tabsList,
      sumInEur,
      oneOtonInEur,
      ratesState,
    };
  },
});
