import {
  State,
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicOfficeFetch,
} from '@/store/storeUtils';
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { path, pathOr } from 'rambda';

import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FULFILLED, INIT, PENDING, REJECTED,
} from '@/utils/statuses';
import getLang from '@/utils/cookies/getLang';
import router from '@/router';
import qs from 'qs';

import authCookies from '@/utils/cookies/authCookies';
import modals from '@/utils/modals';

export interface UserResponseInterface {
  [key: string]: any;
  id: string;
  auth?: string; // if sso

  banner: boolean;
  spacad: number | string;
  paystreeActionPeriod: number;
  paystree: {
    url: false | string;
    date: number;
    /**
         0 = 'Ссылка свободна',
         1 = 'Ссылка выдана',
         2 = 'Карта оформлена',
         3 = 'Ссылка просрочена',
         4 = 'Ссылка перевыпущена'
    */
    status: 0 | 1 | 2 | 3 | 4;
  };

  paymentStatus: number;

  qualify_text?: string;
  qualify: 0 | 1;
  login: string;
  email: string;
  date_auth: string;
  lang: string;
  ref: null | string;
  date_reg: string;
  firstName: string;
  lastName: string;
  activated: string;
  account_type: string;
  account_type_activated: string;
  fake: string;
  block: string;
  send: string;
  rank: string;
  account_start: string;
  btc: string;
  city: string;
  name: string;
  test: string;
  phone: string;
  token: string;
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  line_5: string;
  line_6: string;
  bitcoin: string;
  btc_old: string;
  chat_id: string;
  accept_1: string;
  accept_2: string;
  accept_3: string;
  accept_4: string;
  accept_5: string;
  kemerovo: string;
  telegram: string;
  is_leader: string;
  is_master: string;
  disclaimer: string;
  is_manager: string;
  translator: string;
  accept_oton_1: string;
  accept_oton_2: string;
  accept_oton_3: string;
  accept_oton_4: string;
  accept_oton_5: string;
  canCloseTicket: string;
  countryIsoCode: string;
  is_grand_master: string;
  accept_new_terms: string;
  binary_placement: string;
  is_platin_master: string;
  accept_oton_charter: string;
  accept_new_terms_03_2019: string;
  accept_new_terms_05_2019: string;
  accept_new_terms_09_2019: string;
  accept_oton_2020_03_16_1: string;
  accept_oton_2020_03_16_2: string;
  accept_oton_2020_03_16_3: string;
  accept_oton_2020_03_16_4: string;
  accept_oton_2020_03_16_5: string;
  accept_oton_2020_03_16_6: string;
  accept_oton_2021_03_25_1: string;
  accept_oton_2021_03_25_2: string;
  accept_oton_2021_03_25_3: string;
  accept_oton_2021_03_25_4: string;
  accept_oton_2021_03_25_5: string;
  accept_oton_2021_03_25_6: string;
  email_hash: string;
  access: string;
  balance: {
    '4art': {
      id: '17';
      name: '4ART';
      symbol: '4ART';
      decimal_places: '4';
      slower: '4art';
      symbol2: '4ART';
      balance: string;
    };
    btc: {
      id: '1';
      name: 'Bitcoin';
      symbol: 'BTC';
      decimal_places: '8';
      slower: 'btc';
      symbol2: 'BTC';
      balance: string;
    };
    cp: {
      id: '29';
      name: 'Career points';
      symbol: 'CP';
      decimal_places: '8';
      slower: 'cp';
      symbol2: 'CP';
      balance: string;
    };
    concertvr: {
      id: '19';
      name: 'ConcertVR';
      symbol: 'CONCERTVR';
      decimal_places: '5';
      slower: 'concertvr';
      symbol2: 'CONCERTVR';
      balance: string;
    };
    ebp: {
      id: '21';
      name: 'EasyBizzi Points';
      symbol: 'EBP';
      decimal_places: '8';
      slower: 'ebp';
      symbol2: 'EBP';
      balance: string;
    };
    eur: {
      id: '22';
      name: 'Euro';
      symbol: 'EUR';
      decimal_places: '8';
      slower: 'eur';
      symbol2: 'EUR';
      balance: string;
    };
    eurp: {
      id: '25';
      name: 'Euro (PrivatCard)';
      symbol: 'EURP';
      decimal_places: '8';
      slower: 'eurp';
      symbol2: 'EUR';
      balance: string;
    };
    eurki: {
      id: '30';
      name: 'KYC Income';
      symbol: 'EURKI';
      decimal_places: '8';
      slower: 'eurki';
      symbol2: 'EURKI';
      balance: string;
    };
    eurks: {
      id: '31';
      name: 'KYC Spent';
      symbol: 'EURKS';
      decimal_places: '8';
      slower: 'eurks';
      symbol2: 'EURKS';
      balance: string;
    };
    eurc: {
      id: '26';
      name: 'MPL Bonus Points';
      symbol: 'EURC';
      decimal_places: '8';
      slower: 'eurc';
      symbol2: 'EUR';
      balance: string;
    };
    oton: {
      id: '18';
      name: 'OTON';
      symbol: 'OTON';
      decimal_places: '5';
      slower: 'oton';
      symbol2: 'OTON';
      balance: string;
    };
    otonq: {
      id: '36';
      name: 'OTON Quota';
      symbol: 'OTONQ';
      decimal_places: '8';
      slower: 'otonq';
      symbol2: 'OTONQ';
      balance: string;
    };
    otonh: {
      id: '33';
      name: 'HW OTON';
      symbol: 'OTONH';
      decimal_places: '5';
      slower: 'otonh';
      symbol2: 'OTONH';
      balance: string;
    };
    trade: {
      id: '20';
      name: 'SmartTrade';
      symbol: 'TRADE';
      decimal_places: '5';
      slower: 'trade';
      symbol2: 'TRADE';
      balance: string;
    };
    try: {
      id: '27';
      name: 'Turkish Lira';
      symbol: 'TRY';
      decimal_places: '8';
      slower: 'try';
      symbol2: 'TRY';
      balance: string;
    };
    usdt: {
      id: '23';
      name: 'USDT';
      symbol: 'USDT';
      decimal_places: '8';
      slower: 'usdt';
      symbol2: 'USD';
      balance: string;
    };
    otonb: {
      id: '34';
      name: 'OTON';
      symbol: 'OTON';
      decimal_places: '8';
      slower: 'oton';
      symbol2: 'OTONB';
      balance: string;
    };
  };
  '2FA': boolean;
  line_1_users: number;
  max_packet: number;
  start: {
    reg: number;
    expire: number;
    prev: number;
    earned: number;
    change: {
      sum: number;
      date_relative: number;
      date: number;
      sum_inc: number;
      date_relative_inc: number;
      date_inc: number;
    };
  };
  cards: number;
  // 2 - full KYC
  // 6 - part KYC
  kyc: number;
  gotVipPresent: number;
  pay: number;
  kyc_limit: {
    status: {
      id: number;
      name: string;
    };
    limit: {
      max: number;
      amount_in: number;
      amount_out: number;
      date: number;
    };
  };
  withdraw_limit: {
    btc: number;
    oton: number;
    eur: number;
  };
}

export interface UserStoreInterface extends FetchOfficeState<UserResponseInterface> {
  isAuth: boolean;
  isForgot: boolean;
  with2FaAuth: boolean;
}

const userStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<UserResponseInterface>(),
    isAuth: !!authCookies.get(),
    with2FaAuth: false,
  } as UserStoreInterface,

  getters: {
    data(...args): UserResponseInterface | undefined {
      const { state } = getterCtx(args);

      const result = path(['data', 'data'], state.response) as UserResponseInterface;

      return result;
    },

    userWith2Fa(...args): boolean {
      const { state, getters } = getterCtx(args);

      const result = state.with2FaAuth || getters?.data?.['2FA'] || false;

      return result;
    },

  },
  mutations: {
    ...getFetchMutations(),
    SET_AUTH(state, isAuth: boolean) {
      state.isAuth = isAuth;
    },
    SET_INIT(state) {
      state.fetchState = INIT;
    },
    UPDATE_DATA(state, payload: any) {
      if (!state.response) {
        return;
      }

      const newResponse = {
        ...state.response,
        data: {
          ...state.response.data,
          data: {
            ...state.response.data.data,
            ...payload,
          },
        },
      };

      state.response = newResponse;
    },
    UPDATE_FORGOT(state, payload: boolean) {
      state.isForgot = payload;
    },
    SET_TWO_AUTH(state, two: boolean) {
      state.with2FaAuth = two;
    },
  },
  actions: {
    async fetch(ctx) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      if (state.isAuth) {
        await basicOfficeFetch({
          method: api.office.sendPost,
          props: {
            url: '/user/info',
          },
          setState: commit.SET_STATE,
          // update state
          callback: (fetchState) => {
            if (fetchState === REJECTED) {
              dispatch.dropAuth();
            }
          },
        });
      }
    },

    async register(ctx, payload: {
      email: string;
      password: string;
      password1: string;
      ref: string | number | undefined;
      place: 'left' | 'right' | 'default' | undefined;
    }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/register',
          data: {
            ...payload,
          },
        },
        setState: commit.SET_STATE,
        callback: async (fetchState) => {
          if (fetchState === REJECTED) {
            dispatch.dropAuth({ withoutRedirect: true });
          }

          if (fetchState === FULFILLED) {
            commit.SET_INIT();
            const auth = state.response?.data.data.auth;

            if (!auth) {
              dispatch.dropAuth();
              return;
            }

            authCookies.set(auth);

            await dispatch.fetch();

            commit.SET_AUTH(!!auth);

            router.replace('/');
          }
        },
      });
    },

    async setAuth(ctx, { auth }: { auth?: string; }) {
      const { commit, dispatch } = actionCtx(ctx);
      commit.SET_INIT();

      if (!auth) {
        dispatch.dropAuth();
        return;
      }

      authCookies.set(auth);

      await dispatch.fetch();

      commit.SET_AUTH(!!auth);

      router.replace('/');
    },

    async loginWithKey(ctx, { key, code }: {key: string; code: 4 | 2}) {
      const {
        commit, dispatch, rootDispatch,
      } = actionCtx(ctx);

      // TODO: work with 5000 code with mean force KYC
      const authTypes = {
        2: {
          type: 'two',
          url: '/user/auth2fa',
        },
        4: {
          type: 'pin',
          url: '/user/authEmail',
        },
      };

      const authType = authTypes[code];

      commit.SET_STATE({ fetchState: INIT });

      if (authType.type === 'two') {
        commit.SET_TWO_AUTH(true);
      }

      const authorize = (two: string) => {
        basicOfficeFetch({
          method: api.office.sendPost,
          props: {
            url: authType.url,
            data: {
              key, two,
            },
          },
          setState: ({ fetchState }) => commit.SET_STATE({ fetchState }),
          callback: (fetchState, resp) => {
            if (fetchState === FULFILLED) {
              const auth = resp?.data?.data?.auth;

              if (!auth) {
                dispatch.dropAuth();
                return;
              }

              commit.SET_TWO_AUTH(false);

              dispatch.setAuth({ auth });
            }
          },
        });
      };

      rootDispatch.twoFaStore.with2FAData(authorize);
    },

    async login(ctx, { email, password }: {email: string; password: string}) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      const callback = (fetchState: State, response?: any) => {
        if (fetchState === FULFILLED) {
          const auth = response?.data?.data?.auth;

          if (!auth) {
            dispatch.dropAuth();
            return;
          }

          dispatch.setAuth({ auth });
        }

        if (fetchState === REJECTED && response) {
          const { code, data } = response.data;
          const key = data?.key;

          if (code === 5000) {
            modals.kyc.openModal({
              userId: pathOr('', ['data', 'data', 'user_id'], response),
              force: true,
            });
            return;
          }

          if (!key) {
            return;
          }

          dispatch.loginWithKey({ code, key });
        }
      };

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/auth',
          data: {
            email, password,
          },
        },
        setState: ({ fetchState }) => commit.SET_STATE({ fetchState }),
        callback,
      });
    },

    async setProfile(ctx, { data, onSuccess }: {data: {[key:string]: any}, onSuccess?(): void }) {
      const {
        commit, state, dispatch, rootDispatch,
      } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      rootDispatch.twoFaStore.with2FAData((two) => {
        basicOfficeFetch({
          method: api.office.sendPost,
          props: {
            url: '/user/setProfile',
            data: {
              two,
              data: JSON.stringify(data),
            },
          },
          setState: ({ fetchState }) => commit.SET_STATE({ fetchState: fetchState === REJECTED ? FULFILLED : fetchState }), // superHach better will be create another one SET_STATE for set profile
          callback: (fetchState) => {
            if (fetchState === FULFILLED) {
              dispatch.fetch();
              onSuccess?.();
            }
          },
        });
      });
    },

    async easyAuth(ctx, token: string) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      const app = process.env.VUE_APP_SSO_TOKEN;
      const ssoUrl = process.env.VUE_APP_SSO_URL;

      await basicOfficeFetch({
        method: () => api.office.clearPost({
          baseURL: ssoUrl,
          url: '',
          data: qs.stringify({ token, app, lang: getLang() }),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }),

        setState: commit.SET_STATE,
        // update state
        callback: async (fetchState) => {
          if (fetchState === REJECTED) {
            dispatch.dropAuth();
          }

          if (fetchState === FULFILLED) {
            commit.SET_INIT();
            const auth = state.response?.data.data.auth;

            if (!auth) {
              dispatch.dropAuth();
              return;
            }

            authCookies.set(auth);

            await dispatch.fetch();

            commit.SET_AUTH(!!auth);

            router.replace('/');
          }
        },
      });
    },

    forgotPassword(ctx, email: string) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/forgot',
          data: {
            email,
          },
        },
        setState: commit.SET_STATE,
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            dispatch.dropAuth({ withoutRedirect: true });
            commit.UPDATE_FORGOT(false);
          }
          if (fetchState === FULFILLED) {
            commit.UPDATE_FORGOT(true);
          }
        },
      });
    },

    saveNewPassword(ctx, {
      code,
      password,
      password1,
    }: {
      code: string,
      password: string;
      password1: string;
    }) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/forgotSave',
          data: {
            code,
            password,
            password1,
          },
        },
        setState: commit.SET_STATE,
        callback: (fetchState) => {
          if (fetchState === FULFILLED) {
            router.push('/login');
          }
        },
      });
    },

    dropAuth(ctx, payload?: {withoutRedirect?: boolean}) {
      const { commit } = actionCtx(ctx);

      authCookies.remove();
      commit.SET_AUTH(false);
      if (!(payload || {}).withoutRedirect) {
        router.push('/login');
      }
    },
  },
});

export default userStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, userStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, userStore);
