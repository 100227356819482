
// import modals from '@/utils/modals';
import store from '@/store';
import routeHash from '@/utils/routeHash';
import { defineComponent, computed } from 'vue';
import { UserContactItemInterface } from '@/interfaces/userContactsTypes';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonButtonSecondary from './CommonButtonSecondary.vue';
// import CommonButtonMore from './CommonButtonMore.vue';
import CommonInput from './CommonInput.vue';
import CommonLoader from './CommonLoader.vue';

export default defineComponent({
  name: 'ContactsForm',
  components: {
    CommonInput,
    CommonButtonPrimary,
    CommonButtonSecondary,
    // CommonButtonMore,
    CommonLoader,
  },
  setup() {
    const editState = computed(() => store.state.contactsUserStore.editState);
    const hash = computed(() => store.getters.routeStore.hash);
    const data = computed(() => ({
      id: hash.value.id || '',
      name: hash.value.name || '',
      email: hash.value.email || '',
      telegram: hash.value.telegram || '',
      btcAddress: hash.value.btcAddress || '',
    } as UserContactItemInterface));

    const onChange = (name: string, value: string) => routeHash.replace({ [name]: value });

    const onSave = () => {
      const payload: UserContactItemInterface = {
        name: data.value.name,
        btcAddress: data.value.btcAddress,
        email: data.value.email,
        telegram: data.value.telegram,
      };

      if (data.value.id) {
        payload.id = data.value.id;
      }

      if (payload.btcAddress === '') {
        return;
      }

      store.dispatch.contactsUserStore.editContact(payload);
    };

    const onRemove = async () => {
      if (!data.value.id) {
        return;
      }

      const userAnswer = await store.dispatch.confirmModalStore.askConfirm();

      if (userAnswer) {
        store.dispatch.contactsUserStore.removeContact(data.value.id);
      }
    };

    return {
      editState,
      data,
      onChange,
      onSave,
      onRemove,
    };
  },
});
