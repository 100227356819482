/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { path } from 'rambda';

import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicOfficeFetch,
} from '@/store/storeUtils';
import {
  PENDING, REJECTED,
} from '@/utils/statuses';

export interface PaystreeConditionsInterface {
  actionInProgress: boolean;
  packet: boolean;
  isPaid: boolean;
  residence: boolean;
  terms: boolean;
  kyc: boolean;
}

type StateInterface = FetchOfficeState<PaystreeConditionsInterface>;

const paystreeСonditionsStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<StateInterface>(),
  } as StateInterface,

  getters: {
    canBuy(...args): boolean {
      const {
        getters: {
          data: {
            actionInProgress, packet, residence, kyc,
          },
        },
      } = getterCtx(args);

      return actionInProgress && packet && residence && kyc;
    },
    data(...args): PaystreeConditionsInterface {
      const { state } = getterCtx(args);

      const result = path('data.data', state.response) as PaystreeConditionsInterface;

      if (!result) {
        return {
          actionInProgress: false,
          packet: false,
          residence: false,
          isPaid: false,
          terms: false,
          kyc: false,
        };
      }

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
  },
  actions: {

    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/paystree/conditions',
          data: {},
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            console.error('Fetch error in paystreeСonditionsStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default paystreeСonditionsStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, paystreeСonditionsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, paystreeСonditionsStore);
