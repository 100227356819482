import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactsForm = _resolveComponent("ContactsForm")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    title: _ctx.data.id ? _ctx.data.name : _ctx.$t('Add contact'),
    name: _ctx.modalName
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ContactsForm)
    ]),
    _: 1
  }, 8, ["title", "name"]))
}